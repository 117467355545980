import * as React from "react"
import Layout from "../components/layout"

export default function IndexPage() {
  return (
    <Layout>
      <div className="flex h-screen">
        <div className="px-4 py-8 m-auto w-full lg:w-1/2">
          <div className="text-4xl lg:text-6xl mb-6">Kontakt</div>
          <div className="text-xl">
            <p>
              <p>
                <a href="mailto:lacucinakristinehamn@gmail.com">
                  lacucinakristinehamn@gmail.com
                </a>
                <br />
                Kungsgatan 52 <br />
                681 31 Kristinehamn <br />
                Telefon: 0550-82320 alt. 0723142567
                <br />
                <br />
              </p>
              <p>
                Vill du anordna en fest eller ett möte? Då kan du boka vår
                källare som är perfekt för större sällskap och om ni vill sitta avskilt från andra gäster. Kontakta oss via mail eller telefon för mer information
                och för paketpriser.
              </p>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
